import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from "comma-number";

const Products = ({ icon }) => {
    const [data, setData] = useState([]);
    const [category, setCategory] = useState([]);
    const [brand, setBrand] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [currentCategory, setCurrentCategory] = useState([]);
    const [currentBrand, setCurrentBrand] = useState([]);
    const [currentProductId, setCurrentProductId] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const getData = async () => {
        try {
            const [productsRes, categoryRes, brandRes] = await Promise.all([
                axios.get('/product'),
                axios.get('/category'),
                axios.get('/brand')
            ]);

            setData(productsRes.data);
            setCategory(categoryRes.data.map(item => ({
                value: item.id,
                label: item.category_name_en,
            })));
            setBrand(brandRes.data.map(item => ({
                value: item.id,
                label: item.brand_name,
            })));
        } catch (error) {
            toast.error('Error loading data');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);

    const uploadChange = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return;

            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", file);

            const { data } = await axios.post("/upload", formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            setSelectedFile(data);
            toast.success('File uploaded successfully');
        } catch (error) {
            toast.error('Error uploading file');
            console.error("Error uploading file:", error);
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const uploadImages = async (e) => {
        try {
            setIsUploading(true);
            const files = Array.from(e.target.files);

            const validFiles = files.filter(file =>
                file.type.startsWith('image/') || file.type === 'application/pdf'
            );

            if (validFiles.length !== files.length) {
                toast.warning('Some files were skipped - invalid format');
            }

            if (validFiles.length === 0) {
                toast.error('No valid files selected');
                return;
            }

            const formData = new FormData();
            validFiles.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });

            const { data } = await axios.post("/upload-images", formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            setSelectedFiles(prevFiles => [...prevFiles, ...data]);
            toast.success('Images uploaded successfully');
        } catch (error) {
            toast.error('Error uploading images');
            console.error("Error uploading files:", error);
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const removeImage = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const deleteProduct = async (productId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: "Yes, delete it!",
            });

            if (result.isConfirmed) {
                await axios.delete(`/product/${productId}`);
                toast.success('Product deleted successfully');
                getData();
            }
        } catch (error) {
            toast.error('Error deleting product');
            console.error("Error deleting product:", error);
        }
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Products</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Product List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Product</span>
                                    </button>
                                </div>
                            </div>

                            {/* New Product Modal */}
                            <div className="modal fade" id="new" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">New Product</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <form onSubmit={async (e) => {
                                            e.preventDefault();
                                            try {
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                const response = await axios.post('/product', data);
                                                setCurrentProductId(response.data);
                                                getData();
                                                window.$('#new').modal('hide');
                                                window.$('#gallery').modal('show');
                                                toast.success('Product created successfully');
                                            } catch (error) {
                                                toast.error('Error creating product');
                                                console.error("Error creating product:", error);
                                            }
                                        }}>
                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label className="form-label">Category</label>
                                                    <Select
                                                        options={category}
                                                        name="category_id"
                                                        placeholder="Select Category"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Brand</label>
                                                    <Select
                                                        options={brand}
                                                        name="brand_id"
                                                        placeholder="Select Brand"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Product Name (English)</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="product_name_en"
                                                        placeholder="Product Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Product Name (Arabic)</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="product_name_ar"
                                                        placeholder="Product Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Description (English)</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="description_en"
                                                        placeholder="Description"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Description (Arabic)</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="description_ar"
                                                        placeholder="Description"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Price</label>
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        className="form-control"
                                                        name="price"
                                                        placeholder="0.00"
                                                        defaultValue={0.00}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Discount</label>
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        className="form-control"
                                                        name="discount"
                                                        placeholder="0.00"
                                                        defaultValue={0.00}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Thumbnail Image</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={uploadChange}
                                                        accept="image/*,application/pdf"
                                                        required
                                                        disabled={isUploading}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="thumbnail"
                                                        value={selectedFile || ''}
                                                        required
                                                    />
                                                    {uploadProgress > 0 && (
                                                        <div className="progress mt-2">
                                                            <div
                                                                className="progress-bar"
                                                                style={{ width: `${uploadProgress}%` }}
                                                                role="progressbar"
                                                                aria-valuenow={uploadProgress}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {uploadProgress}%
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isUploading}
                                                >
                                                    {isUploading ? 'Uploading...' : 'Next'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* Gallery Modal */}
                            <div className="modal fade" id="gallery" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Add Product Images</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <form onSubmit={async (e) => {
                                            e.preventDefault();
                                            try {
                                                if (selectedFiles.length === 0) {
                                                    window.$('#gallery').modal('hide');
                                                    return;
                                                }

                                                await axios.post('/product-image', {
                                                    images: selectedFiles,
                                                    product_id: currentProductId
                                                });

                                                window.$('#gallery').modal('hide');
                                                toast.success('Images added successfully');
                                                getData();
                                                setSelectedFiles([]);
                                            } catch (error) {
                                                toast.error('Error saving images');
                                                console.error("Error saving images:", error);
                                            }
                                        }}>
                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label className="form-label">Additional Images</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={uploadImages}
                                                        accept="image/*,application/pdf"
                                                        multiple
                                                        disabled={isUploading}
                                                    />
                                                    {uploadProgress > 0 && (
                                                        <div className="progress mt-2">
                                                            <div
                                                                className="progress-bar"
                                                                style={{ width: `${uploadProgress}%` }}
                                                                role="progressbar"
                                                                aria-valuenow={uploadProgress}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {uploadProgress}%
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row mt-3">
                                                        {selectedFiles.map((file, index) => (
                                                            <div className="col-md-3 position-relative mb-3" key={index}>
                                                                <img
                                                                    className="img-fluid rounded"
                                                                    src={file}
                                                                    alt={`uploaded-img-${index}`}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                                                    onClick={() => removeImage(index)}
                                                                >
                                                                    ×
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Skip
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isUploading}
                                                >
                                                    {isUploading ? 'Uploading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* Product Table */}
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Product (English)</th>
                                        <th>Category</th>
                                        <th>Brand</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>Discount Price</th>
                                        <th>Views</th>
                                        <th>Created At</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.product_id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <img
                                                    src={item.thumbnail}
                                                    alt={item.product_name_en}
                                                    className="img-thumbnail"
                                                    style={{ maxWidth: '50px' }}
                                                />
                                            </td>
                                            <td>{item.product_name_en}</td>
                                            <td>{item.category_name_en}</td>
                                            <td>{item.brand_name}</td>
                                            <td>${commaNumber(item.price)}</td>
                                            <td>{item.discount}%</td>
                                            <td>${commaNumber(item.discounted_price)}</td>
                                            <td>{commaNumber(item.views)}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning btn-sm me-1"
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#edit${item.product_id}`}
                                                    onClick={() => {
                                                        setCurrentCategory({
                                                            value: item.category_id,
                                                            label: item.category_name_en
                                                        });
                                                        setCurrentBrand({
                                                            value: item.brand_id,
                                                            label: item.brand_name
                                                        });
                                                        setCurrentProductId(item.product_id);
                                                        setSelectedFiles([]);
                                                    }}
                                                >
                                                    <icon.Edit size={16} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => deleteProduct(item.product_id)}
                                                >
                                                    <icon.Trash size={16} />
                                                </button>

                                                {/* Edit Modal */}
                                                <div className="modal fade" id={`edit${item.product_id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">Edit {item.product_name_en}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={async (e) => {
                                                                e.preventDefault();
                                                                try {
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    await axios.put(`/product/${item.product_id}`, data);
                                                                    getData();
                                                                    window.$(`#edit${item.product_id}`).modal('hide');
                                                                    window.$('#editgallery').modal('show');
                                                                    toast.success('Product updated successfully');
                                                                } catch (error) {
                                                                    toast.error('Error updating product');
                                                                    console.error("Error updating product:", error);
                                                                }
                                                            }}>
                                                                <div className="modal-body">
                                                                    {/* Same form fields as New Product Modal */}
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Category</label>
                                                                        <Select
                                                                            options={category}
                                                                            name="category_id"
                                                                            value={currentCategory}
                                                                            onChange={(e) => setCurrentCategory(e)}
                                                                            placeholder="Select Category"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Brand</label>
                                                                        <Select
                                                                            options={brand}
                                                                            name="brand_id"
                                                                            value={currentBrand}
                                                                            onChange={(e) => setCurrentBrand(e)}
                                                                            placeholder="Select Brand"
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Product Name (English)</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="product_name_en"
                                                                            defaultValue={item.product_name_en}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Product Name (Arabic)</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="product_name_ar"
                                                                            defaultValue={item.product_name_ar}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description (English)</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="description_en"
                                                                            defaultValue={item.description_en}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description (Arabic)</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="description_ar"
                                                                            defaultValue={item.description_ar}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Price</label>
                                                                        <input
                                                                            type="number"
                                                                            step="any"
                                                                            className="form-control"
                                                                            name="price"
                                                                            defaultValue={item.price}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Discount</label>
                                                                        <input
                                                                            type="number"
                                                                            step="any"
                                                                            className="form-control"
                                                                            name="discount"
                                                                            defaultValue={item.discount}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Thumbnail Image</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            onChange={uploadChange}
                                                                            accept="image/*,application/pdf"
                                                                            disabled={isUploading}
                                                                        />
                                                                        <input
                                                                            type="hidden"
                                                                            name="thumbnail"
                                                                            value={selectedFile || item.thumbnail}
                                                                        />
                                                                        {uploadProgress > 0 && (
                                                                            <div className="progress mt-2">
                                                                                <div
                                                                                    className="progress-bar"
                                                                                    style={{ width: `${uploadProgress}%` }}
                                                                                    role="progressbar"
                                                                                    aria-valuenow={uploadProgress}
                                                                                    aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                >
                                                                                    {uploadProgress}%
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {item.thumbnail && (
                                                                            <img
                                                                                src={item.thumbnail}
                                                                                alt={item.product_name_en}
                                                                                className="img-thumbnail mt-2"
                                                                                style={{ maxWidth: '100px' }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-secondary"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary"
                                                                        disabled={isUploading}
                                                                    >
                                                                        {isUploading ? 'Uploading...' : 'Update'}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}
export default Products;